import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import Image from 'next/image'
import { Testimonial } from '@/components/TestimonialWrapper/TestimonialWrapper.model'
import styles from './testimonial.module.scss'

const fourColsOption = {
  span: null,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const eightColsOption = {
  span: null,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 6,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const imageBlock: any = {
  ...fourColsOption,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 3,
    small: 4,
  },
}

const textBlock: any = {
  ...eightColsOption,
  spans: {
    xlarge: 10,
    large: 10,
    medium: 5,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const Testimonial = ({
  image,
  title,
  comment,
  commentAuthor,
  commentAuthorTitle,
}: Testimonial) => {
  return (
    <>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount="4"
        justifyContent={defaultGridOptions.justifyContent}
        display="grid"
        padding="none"
      >
        <USBColumn layoutOpts={imageBlock}>
          <div className={styles.testimonialImageWrapper}>
            <img
              src={process.env.CONTENT_DOMAIN + image}
              alt=""
              className={styles.testimonialImage}
            />
          </div>
        </USBColumn>
        <USBColumn layoutOpts={textBlock}>
          <div className={styles.textBlock}>
            <h3
              className={styles.headH3}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h3>
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: comment }}
            ></p>
            <p className={styles.paragraph}>
              &mdash;{commentAuthor}, {commentAuthorTitle}
            </p>
          </div>
        </USBColumn>
      </USBGrid>
    </>
  )
}

export default Testimonial
