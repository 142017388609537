import displayPage from '@/layout/PageLayout'
import { getServerSideProps } from '@/modules/home/home.props'

// View
import Home from '@/modules/home/Home'

// Get Props
export { getServerSideProps }

// Render
export default displayPage(Home)
