import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import Testimonial from './Testimonial'
import { TestimonialProps } from './TestimonialWrapper.model'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import styles from '@/components/TestimonialWrapper/testimonial.module.scss'

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const TestimonialWrapper = ({
  title,
  shortDescription,
  testimonial,
}: TestimonialProps) => {
  const renderTestimonials = testimonial.map(function (value, index) {
    return (
      <Testimonial
        key={index}
        image={value.image}
        title={value.title}
        comment={value.comment}
        commentAuthor={value.commentAuthor}
        commentAuthorTitle={value.commentAuthorTitle}
        commentAuthorOrg={value.commentAuthorOrg}
      />
    )
  })

  return (
    <div id={styles.sectionRow} className={styles.sectionRow}>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        addClasses={styles.gridWrapper}
      >
        <USBColumn layoutOpts={twelveColsOption}>
          <h2 className={styles.headH2}>{title}</h2>
          <p
            className={styles.paragraph}
            dangerouslySetInnerHTML={{
              __html: shortDescription,
            }}
          ></p>
          {renderTestimonials}
        </USBColumn>
      </USBGrid>
    </div>
  )
}

export default TestimonialWrapper
